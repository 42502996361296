import { get, isNil } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup } from 'reactstrap';
import Listview from 'jsx/components/core/form/components/Listview';
import { acceptedByUser, cancelledByUser } from 'jsx/constants/keycodes';
import { createAssumedAnimalClassValue } from 'jsx/components/manage/actions/livestock_assumptions';

import {
  decimalNumberFormatter,
  moneyFormatter,
  wholeNumberFormatter,
} from 'jsx/components/core/form/lib/numberFormat';
import { createAnimalClassValue } from '../actions/animal_classes';

interface LivestockMonthlyGridProps {
  isAdmin: boolean;
  isAssumedValuesToggled: boolean;
  onRefresh: () => void;
  valueType: string;
}

const baseHeaders: Array<Record<string, string>> = [
  { caption: 'Property', field: 'property.name', classes: 'text-left' },
  { caption: 'Enterprise Name', field: 'enterprise.name', classes: 'text-left' },
  { caption: 'Animal Class', field: 'animal_class.name', classes: 'text-left' }
];

const bmHeaders: Array<Record<string, string>> = [
  {
    caption: 'Use BM Avg Values',
    field: 'assumed_animal_class.use_average_values',
    classes: 'text-center',
  },
  {
    caption: 'Avg 12-Month ($/kg)',
    field: 'assumed_animal_class.average_value',
    classes: 'text-center',
  },
];

const adminHeaders: Array<Record<string, string>> = [
  {
    caption: 'Class',
    field: 'assumed_class.assumed_animal_class.name',
    classes: 'text-left',
  },
  {
    caption: 'Division',
    field: 'assumed_class.assumed_animal_class.type.parent.name',
    classes: 'text-left',
  },
  {
    caption: 'Use Avg Values',
    field: 'assumed_class.assumed_animal_class.use_average_values',
    classes: 'text-center',
  },
  {
    caption: '12-Month Avg ($/kg)',
    field: 'assumed_class.average_value',
    classes: 'text-center',
  },
];

type ByMonthRowValue = {
  value: number;
  date: string;
  sourceDate: string;
};

type AssumedClassProps = {
  assumed_animal_class: {
    id: string;
    name: string;
  };
};

type ByMonthRow = {
  assumed_class?: AssumedClassProps;
  name?: string;
  enterprise?: {
    id: string;
    name: string;
  };
  property?: {
    id: string;
    name: string;
  };
  animal_class?: {
    id: string;
    name: string;
  };
  values: Array<ByMonthRowValue>;
};

export type ByMonth = {
  valueType: 'au_rating' | 'value' | 'weight';
  months: Array<string>;
  rows: Array<ByMonthRow>;
};

type TransactionType = {
  tag: string;
  id: string;
};

const getBaseHeaders = (isAdmin: boolean) => (isAdmin ? adminHeaders : baseHeaders);
const getBmHeaders = (isAssumedValuesToggled: boolean) => (isAssumedValuesToggled ? bmHeaders : []);

const readOnlyOptions = {
  emptyFunction: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  style: { cursor: 'default' },
  className: 'text-center bg-gray',
};

/**
 * Component is used in multiple places:
 *   - Ops (admin portal) where all functionality is expected
 *   - Portal where clients can edit their own values
 *   - If assumed values are toggled, this needs be read only
 */
export const LivestockMonthlyGrid = ({
  isAdmin = false,
  isAssumedValuesToggled = false,
  onRefresh,
}: LivestockMonthlyGridProps) => {
  const dispatch = useDispatch();
  const { byMonth } = useSelector((state: any) => state.animal_classes);
  const { byMonth: byMonthAssumptions } = useSelector((state: any) => state.livestock_assumptions);
  const { months, rows, valueType } = isAdmin
    ? (byMonthAssumptions as ByMonth)
    : (byMonth as ByMonth);

  let headers = getBaseHeaders(isAdmin);
  if (valueType === 'value') headers = headers.concat(getBmHeaders(isAssumedValuesToggled && !isAdmin));

  let valueFormatter = decimalNumberFormatter;
  if (valueType === 'value') {
    valueFormatter = moneyFormatter;
  } else if (valueType === 'weight') {
    valueFormatter = wholeNumberFormatter;
  }

  const { transaction_types } = useSelector((state: any) => state.attributes);
  const adjustmentTransactionType = (transaction_types as Array<TransactionType>).find(
    ({ tag }) => tag === 'adjustment',
  );

  const [hasError, setHasError] = useState(false);

  const tableHeadTh = useMemo(() => {
    const dynamicHeaders = months.map((month) => ({ caption: month, classes: 'w-70px' }));

    return headers.concat(dynamicHeaders).map(({ caption, classes }, index) => (
      <th key={index} className={classes}>
        {caption}
      </th>
    ));
  }, [headers, months]);

  const [isEditing, setIsEditing] = useState<{ x: number; y: number } | null>(null);
  const disableEdit = () => {
    setIsEditing(null);
    setHasError(false);
  };

  const renderBaseCells = (row: ByMonthRow, fieldName: string) => {
    const use_average_value = get(row, 'assumed_class.assumed_animal_class.use_average_values') || get(row, 'assumed_animal_class.use_average_values');
    
    switch (fieldName) {
      case 'assumed_animal_class.use_average_values':
      case 'assumed_class.assumed_animal_class.use_average_values':
        return (get(row, fieldName) ? (<span className="text-success">YES</span>) : 'No');
      case 'assumed_class.average_value':
      case 'assumed_animal_class.average_value':
        return (use_average_value ? (<div className="text-center" style={{fontFamily: 'Roboto, Arial, sans-serif', fontWeight: 700}}>{get(row, fieldName)}</div>) : '');
      default:
        return get(row, fieldName);
    }
  };

  const tableBodyTr = useMemo(() => {
    const handleKeyDown = async (
      event: React.KeyboardEvent<HTMLInputElement>,
      row: ByMonthRow,
      date: string,
    ) => {

      if (cancelledByUser(event)) {
        disableEdit();
      } else if (acceptedByUser(event)) {
        const target = event.target as HTMLInputElement;
        const { value } = target;
        const payloadValue = valueType === 'value' ? value : { value };
        const startOfDate = moment(date).startOf('day').format('YYYY-MM-DDTHH:mmZZ');

        let result = null;
        let params = {
          // Use date format that exactly matches dates that come out of our modal logic for date formatting
          date: startOfDate,
          [valueType]: payloadValue,
        };

        if (isAdmin) {
          params = {
            ...params,
            assumed_animal_class_type_id: row?.assumed_class?.assumed_animal_class?.id ?? '',
          };
          result = await dispatch(createAssumedAnimalClassValue(params));
        } else {
          params = {
            ...params,
            enterprise_id: row?.enterprise?.id ?? '',
            property_id: row?.property?.id ?? '',
            animal_class_id: row?.animal_class?.id ?? '',
            transaction_type_id: adjustmentTransactionType?.id ?? '',
          };
          result = await dispatch(createAnimalClassValue(params));
        }

        if (result as unknown as boolean) {
          disableEdit();
          onRefresh();
        } else {
          setHasError(true);
        }
      }
    };
    return rows.map((row, rowIndex) => {
      const baseCells = headers.map((header, index) => (
        <td key={index} className={header.classes}>{renderBaseCells(row, header.field)}</td>
      ));

      const dynamicCells = row.values.map(({ value, date, sourceDate }, colIndex) => {
        const paddedIndex = colIndex + baseCells.length;
        const isCellEditing = isEditing && isEditing.y === rowIndex && isEditing.x === colIndex;
        const monthlyDiff = moment(date).diff(moment(sourceDate), 'month');
        const displayError = isNil(sourceDate) || monthlyDiff >= 6;
        const displayWarning = !displayError && monthlyDiff >= 3;
        const cellTitle = `As at ${moment(date).format('yyyy-MM-DD')}`;
        let cellClasses = '';

        if (displayError) {
          cellClasses = 'bg-danger text-white';
        } else if (displayWarning) {
          cellClasses = 'bg-warning text-white';
        }

        if (isAssumedValuesToggled) cellClasses = 'bg-gray';

        if (isCellEditing) {
          return (
            <td key={paddedIndex}>
              <FormGroup row className="mt-0 mb-0">
                <Col sm={12}>
                  <div
                    className={`d-flex ${hasError ? 'border border-danger border-solid-2px' : ''}`}
                  >
                    <input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      type="number"
                      className="form-control text-center px-0"
                      defaultValue={value ?? ''}
                      onKeyDown={(event) => handleKeyDown(event, row, date)}
                      onBlur={disableEdit}
                    ></input>
                  </div>
                </Col>
              </FormGroup>
            </td>
          );
        }
        return (
          <td key={paddedIndex} className={cellClasses} title={cellTitle}>
            <div
              role="button"
              tabIndex={0}
              onClick={
                isAssumedValuesToggled
                  ? readOnlyOptions.emptyFunction
                  : () => setIsEditing({ x: colIndex, y: rowIndex })
              }
              onKeyDown={
                isAssumedValuesToggled
                  ? readOnlyOptions.emptyFunction
                  : () => setIsEditing({ x: colIndex, y: rowIndex })
              }
              className="text-center"
              style={isAssumedValuesToggled ? readOnlyOptions.style : {}}
            >
              {valueFormatter(value)}
            </div>
          </td>
        );
      });
      return <tr key={rowIndex}>{baseCells.concat(dynamicCells)}</tr>;
    });
  }, [
    isAssumedValuesToggled,
    isAdmin,
    headers,
    rows,
    valueType,
    dispatch,
    adjustmentTransactionType?.id,
    onRefresh,
    isEditing,
    valueFormatter,
    hasError,
  ]);

  return (
    <Listview
      rows={rows}
      iconName="paw"
      rowsCount={rows.length}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      emptyCaption="No values found"
    />
  );
};
