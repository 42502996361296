import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import GenericModal from 'jsx/components/core/form/components/GenericModal';
import FormTab from 'jsx/components/core/form/components/FormTab';

import FormBase from '../../core/form/components/FormBase';
import GenericLsv from '../../core/form/components/GenericLsv';
import { controls as groupControlsTemplate } from '../forms/analysis_groups';
import {
  createAnalysisGroup,
  fetchAnalysisGroup,
  fetchAnalysisGroups,
  removeAnalysisGroup,
  setAnalysisGroupParams,
  updateAnalysisGroup,
} from '../actions/analysis_groups';
import GroupsToolbar from '../components/analysis-groups/GroupsToolbar';
import AnalysisGroupMembers from './AnalysisGroupMembers';
import { fetchAnalysisGroupMembers } from '../actions/analysis_group_members';

class AnalysisGroups extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(groupControlsTemplate),
      data: {},
      isOpen: false,
      searchValue: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAnalysisGroups());
  }

  toggleTab = (tab) => {
    const { activeTab } = this.props.analysis_groups;
    if (activeTab !== tab)
      this.props.dispatch({ type: 'SET_ANALYSIS_GROUPS_ACTIVE_TAB', payload: tab });

    switch (tab) {
      case 'groups': {
        this.props.dispatch(fetchAnalysisGroups());
        break;
      }
      case 'members': {
        this.props.dispatch(fetchAnalysisGroupMembers());
        break;
      }
      default: {
        break;
      }
    }
  };

  setModal = (isOpen) => {
    const { data } = this.state;
    let updatedState = { isOpen };
    if (!isOpen && !isEmpty(data))
      updatedState = { ...updatedState, data: {}, controls: cloneDeep(groupControlsTemplate) };

    this.setState(updatedState);
  };

  onChange = () => {
    const { searchValue } = this.state;
    this.props.dispatch(setAnalysisGroupParams({ search_value: searchValue }));
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onChange();
  };

  onGroupEdit = async (id) => {
    const data = await this.props.dispatch(fetchAnalysisGroup({ id }));
    if (!data?.id) return;

    this.setState({ data, isOpen: true });
  };

  onGroupSave = async (data, isNew, changes) => {
    const updatedData = isNew ? data : changes;
    if (isNew) delete updatedData.id;

    const saveMethod = isNew ? createAnalysisGroup : updateAnalysisGroup;

    return this.props.dispatch(saveMethod(updatedData));
  };

  onGroupRemove = (id) => this.props.dispatch(removeAnalysisGroup(id));

  onRefresh = () => {
    const { activeTab } = this.props.analysis_groups;
    if (activeTab) this.toggleTab(activeTab);
  };

  onGroupClose = () => {
    // Reset group properties and refetch groups
    this.setState({ isOpen: false, controls: cloneDeep(groupControlsTemplate), data: {} });
    this.onRefresh();
  };

  render() {
    const { controls, data, isOpen, searchValue } = this.state;
    const { activeTab, groups, responseMessage: groupResponseMessage } = this.props.analysis_groups;

    return (
      <>
        <GenericModal
          controls={controls}
          modalTitle="Analysis Group"
          setModal={this.setModal}
          data={data}
          isOpen={isOpen}
          iconName="people-group"
          onSave={this.onGroupSave}
          onRemove={this.onGroupRemove}
          onClose={this.onGroupClose}
          responseMessage={groupResponseMessage}
        />
        <Nav tabs className="mt-2">
          <FormTab
            activeTab={activeTab}
            caption="Groups"
            tabId="groups"
            toggle={this.toggleTab}
            tabTag="groups"
          />
          <FormTab
            caption="Members"
            tabId="members"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="members"
          />
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="groups">
            <GroupsToolbar
              groups={groups}
              searchValue={searchValue}
              onChange={this.onChange}
              handleSearchChange={this.handleSearchChange}
              setModal={this.setModal}
            />
            <GenericLsv
              controls={controls}
              emptyCaption="No Groups found"
              iconName="people-group"
              onClick={(id) => {
                this.onGroupEdit(id);
              }}
              rows={groups}
            />
          </TabPane>
          <TabPane tabId="members">
            <AnalysisGroupMembers onRefresh={this.onRefresh} />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const mapStoreToProps = ({ analysis_groups }) => ({
  analysis_groups,
});

export default connect(mapStoreToProps)(AnalysisGroups);
