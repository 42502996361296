import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep, isEmpty, sum } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import {
  initListviewControls,
  updateListviewControlOptions,
} from 'jsx/components/core/form/lib/validateListview';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as directCostControls } from '../forms/directCosts';
import { controls as distributionBlueprintControls } from '../forms/directCostDistributions';
import { fetchAttributes } from '../actions/attributes';
import {
  fetchDirectCost,
  updateDirectCost,
  createDirectCost,
  removeDirectCost,
} from '../actions/directCosts';
import { fetchEnterpriseDistributions } from '../actions/enterprises';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';
import ResponseMessageTab from '../../../core/form/components/ResponseMessageTab';

import {
  allAreTrue,
  deriveDollarAmount,
  derivePercentage,
  getValidationChecks,
} from '../lib/distributions';
import DirectCostDistributionsLsv from '../components/DirectCostDistributionsLsv';

export class DirectCostModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(directCostControls),
      data: {},
      distributionControls: cloneDeep(distributionBlueprintControls),
      id: null,
      isNew: false,
      title: 'Direct Cost',
    };

    this.filterProperties = this.filterProperties.bind(this);
    this.getDefaultDistribution = this.getDefaultDistribution.bind(this);
    this.loadAttributes = this.loadAttributes.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDistributionAdd = this.onDistributionAdd.bind(this);
    this.onDistributionChange = this.onDistributionChange.bind(this);
    this.onDistributionRemove = this.onDistributionRemove.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.setTotalValue = this.setTotalValue.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { id, division_id } = this.props;
      let controls = initControls(cloneDeep(directCostControls));

      controls.distributions.value = [this.getDefaultDistribution()];

      let distributionControls = initListviewControls(
        controls.distributions.value,
        cloneDeep(distributionBlueprintControls),
      );

      let updatedState = {
        controls,
        data: {},
        distributionControls,
        isNew: true,
        title: 'New Direct Cost',
      };

      this.props.dispatch(fetchAttributes({ type: 'cost_categories', parent_id: division_id }));
      this.props.dispatch(fetchAttributes({ type: 'divisions' }));

      this.props.dispatch(fetchEnterpriseDistributions({ type: 'enterprise', division_id }));

      if (id) {
        const directCost = await this.props.dispatch(fetchDirectCost({ id, division_id }));

        if (directCost) {
          if (directCost?.type?.parent_id) {
            const { parent_id } = directCost.type;
            await this.loadAttributes(parent_id);
            directCost.category_id = parent_id;
          }

          controls = updateControls(controls, directCost);

          if (directCost?.distributions?.length > 0) {
            const { distributions } = directCost;
            distributionControls = [];

            // Merge existing data into blueprint controls
            distributions.forEach((distribution, index) => {
              distributionControls.push(cloneDeep(distributionBlueprintControls));

              distributionControls[index] = updateControls(
                distributionControls[index],
                distribution,
              );

              // Derive $ and % values
              const amount = deriveDollarAmount(directCost.value, distribution.distribution_pcnt);
              distributionControls[index].distribution_amount.value = amount;
              controls.distributions.value[index].distribution_amount = amount;

              const percentage = derivePercentage(directCost.value, amount);
              distributionControls[index].distribution_pcnt.value = percentage;
              controls.distributions.value[index].distribution_pcnt = percentage;
            });
          }

          updatedState = {
            ...updatedState,
            controls,
            data: directCost,
            distributionControls,
            id,
            isNew: false,
            title: 'Edit Direct Cost',
          };
        }
      }

      this.setState(updatedState);
    }
  }

  onDistributionAdd() {
    const { controls, distributionControls } = this.state;

    controls.distributions.value.push(this.getDefaultDistribution());
    distributionControls.push(cloneDeep(distributionBlueprintControls));
    this.setState({ controls, distributionControls });
  }

  deriveTotalValue = (distributions) => {
    if (distributions.length === 0) return 0;

    const values = distributions.map(({ distribution_amount }) =>
      distribution_amount ? parseFloat(distribution_amount) : 0,
    );
    return sum(values);
  };

  onDistributionChange(event, index) {
    const { name, value } = event.target;
    const { controls, distributionControls } = this.state;

    switch (name) {
      case 'distribution_amount': {
        // Display no values if input is empty for both amount/percent
        controls.distributions.value[index][name] = null;
        distributionControls[index][name].value = null;

        controls.distributions.value[index].distribution_pcnt = null;
        distributionControls[index].distribution_pcnt.value = null;

        if (allAreTrue(getValidationChecks(value))) {
          controls.distributions.value[index][name] = value;
          distributionControls[index][name].value = value;
        }

        const totalValue = this.deriveTotalValue(controls.distributions.value);
        controls.value.value = totalValue;
        break;
      }
      case 'enterprise_id': {
        // Update value of enterprise id
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;

        // Update property_id controls to the first associated property of the selected enterprise
        const associatedProperties = this.filterProperties(value);
        controls.distributions.value[index].property_id = associatedProperties[0]?.id ?? null;
        distributionControls[index].property_id.value = associatedProperties[0]?.id ?? null;
        break;
      }
      default: {
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;
        break;
      }
    }

    this.setState({ controls, distributionControls });
  }

  onDistributionRemove(index) {
    const { controls, distributionControls } = this.state;
    const row = controls.distributions.value[index] ?? null;
    if (row) controls.distributions.value.splice(index, 1);

    const control = distributionControls[index] ?? null;
    if (control) distributionControls.splice(index, 1);

    // Recalculate total value
    const totalValue = this.deriveTotalValue(controls.distributions.value);
    controls.value.value = totalValue;

    this.setState({ controls, distributionControls });
  }

  getDefaultDistribution = () => ({
    enterprise_id: null,
    property_id: null,
    distribution_amount: 0,
    distribution_pcnt: 0,
  });

  async onSave(closeModal = true) {
    let { data } = this.state;
    const { controls, isNew } = this.state;

    data = saveControls(controls, data);
    // Convert distribution_pcnt into decimal form
    data.distributions = controls.distributions.value.map((distribution) => {
      const distribution_pcnt = distribution.distribution_pcnt / 100;
      return {
        ...distribution,
        distribution_pcnt,
      };
    });

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    // All form fields are valid
    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createDirectCost(data));
      } else {
        success = await this.props.dispatch(updateDirectCost(data));
      }

      if (success && closeModal) {
        this.onClose(true);
        return;
      }

      // Refetch Direct Costs for background listview
      this.props.onRefresh();

      // Clear value form field and distributions
      const refreshedControls = cloneDeep(updatedControls);
      refreshedControls.value.value = null;
      // Reset distributions through main controls and distribution controls
      refreshedControls.distributions.value.forEach((_, index) => {
        refreshedControls.distributions.value[index].distribution_amount = null;
        refreshedControls.distributions.value[index].distribution_pcnt = null;
      });

      const refreshedDistributionControls = cloneDeep(this.state.distributionControls);
      refreshedDistributionControls.forEach((_, index) => {
        refreshedDistributionControls[index].distribution_amount.value = null;
        refreshedDistributionControls[index].distribution_pcnt.value = null;
      });

      // Reset state
      this.setState({
        controls: refreshedControls,
        distributionControls: refreshedDistributionControls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Direct Cost',
      });
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_DIRECT_COST_ATTRIBUTES' });
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_DISTRIBUTIONS' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} direct cost permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeDirectCost(data.id));
      if (success) this.onClose(true);
    }
  }

  onChange(event) {
    const { controls } = this.state;

    const old_category_id = controls.category_id.value;

    this.handleChange(event);

    const { name, value } = event.target;

    switch (name) {
      case 'category_id':
        // Load another select;
        if (old_category_id !== value) {
          this.loadAttributes(value);
        }
        break;
      default:
        break;
    }
  }

  async loadAttributes(parent_id) {
    if (parent_id === '' || parent_id === '-') {
      this.props.dispatch({ type: 'UNSET_COST_TYPES_ATTRIBUTES' });
    } else {
      await this.props.dispatch(fetchAttributes({ type: 'cost_types', parent_id }));
    }
  }

  setOptions(controls) {
    const { cost_categories, cost_types } = this.props.attributes;
    const typeOptions = {
      category_id: cost_categories,
      type_id: cost_types,
    };

    let updatedControlOptions = cloneDeep(controls);

    Object.entries(typeOptions).forEach(([key, options]) => {
      updatedControlOptions = updateControlOptions(controls, key, options);
    });

    return updatedControlOptions;
  }

  setTotalValue(value) {
    const { controls } = this.state;
    controls.value.value = value;

    this.setState({ controls });
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;
    if (isEmpty(enterprises?.rows)) return [];

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises.rows.find(
      (enterprise) => enterprise.id === enterprise_id,
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  render() {
    let { controls, distributionControls } = this.state;
    const { title, isNew } = this.state;
    const { isOpen, division_id } = this.props;
    const { cost_types, transaction_intervals } = this.props.attributes;
    const { responseMessage } = this.props.direct_costs;
    const { enterprises } = this.props.enterprises;
    const iconName = 'clipboard-list';

    controls = this.setOptions(controls);
    controls.type_id.disabled = cost_types.length === 0;

    if (distributionControls?.length > 0) {
      const filteredEnterprises = enterprises?.rows
        ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
        : [];

      distributionControls = updateListviewControlOptions(
        distributionControls,
        'enterprise_id',
        filteredEnterprises,
      );

      distributionControls.forEach((control, index) => {
        const enterprise_id = control?.enterprise_id?.value ?? null;
        if (enterprise_id) {
          const filteredProperties = this.filterProperties(enterprise_id);
          distributionControls[index] = updateControlOptions(
            control,
            'property_id',
            filteredProperties,
          );
        }

        const { value: distribution_amount } = control.distribution_amount;
        const { value: distribution_pcnt } = control.distribution_pcnt;
        const { value: totalValue } = controls.value;

        // Derive distribution %
        if (
          allAreTrue(getValidationChecks(distribution_amount)) &&
          allAreTrue(getValidationChecks(totalValue))
        ) {
          const percentage = derivePercentage(totalValue, distribution_amount);
          distributionControls[index].distribution_pcnt.value = percentage;
          controls.distributions.value[index].distribution_pcnt = percentage;
        }

        // Derive distribution $
        if (distribution_pcnt > 0 && !distribution_amount) {
          if (
            allAreTrue(getValidationChecks(distribution_pcnt)) &&
            allAreTrue(getValidationChecks(totalValue))
          ) {
            const amount = deriveDollarAmount(totalValue, distribution_pcnt);
            distributionControls[index].distribution_amount.value = amount;
            controls.distributions.value[index].distribution_amount = amount;
          }
        }
      });
    }

    // Copy default option from distributionControls if distribution value is null.
    if (controls.distributions.value?.length > 0) {
      controls.distributions.value.forEach((distribution, index) => {
        Object.keys(distribution).forEach((key) => {
          if (distribution[key] === null && distributionControls[index][key].value) {
            controls.distributions.value[index][key] = distributionControls[index][key].value;
          }
        });
      });
    }

    return (
      <Modal isOpen={isOpen} className="modal-xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>
            <FormInput handleChange={this.onChange} control={controls.category_id} />
            <FormInput handleChange={this.handleChange} control={controls.type_id} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={transaction_intervals}
              intervalKey="transaction_interval_id"
              dateKey="transaction_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.value} />
          </Form>
          <DirectCostDistributionsLsv
            controls={distributionControls}
            onAdd={this.onDistributionAdd}
            onChange={this.onDistributionChange}
            onRemove={this.onDistributionRemove}
            enterprises={enterprises?.rows ?? []}
            rows={controls.distributions.value || []}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            {isNew && (
              <Button size="sm" className="mr-2" color="primary" onClick={() => this.onSave(false)}>
                Save/Add New
              </Button>
            )}
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              {isNew ? 'Save/Close' : 'Save'}
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, direct_costs, enterprises, properties }) => ({
  attributes,
  direct_costs,
  enterprises,
  properties,
});

export default connect(mapStoreToProps)(DirectCostModal);
