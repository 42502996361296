export const checkUserIsAdmin = (props) => {
  if (!props?.auth || !props?.profile) return false;

  // Check if masquerading.
  if (props.auth.isMasquerade) return true;

  // Check current user is an admin.
  const { user_roles = [] } = props.profile.currentUser;
  if (user_roles.length === 0) return false;

  const userRoleTags = user_roles.map(({ role }) => role.tag);
  return userRoleTags.includes('admin');
};

export const checkUserRole = (props, targetRole = '') => {
  if (!props?.auth || !props?.profile) return false;

  const { user_roles = [] } = props.profile.currentUser;
  if (user_roles.length === 0) return false;

  const userRoleTags = user_roles.map(({ role }) => role.tag);
  return userRoleTags.includes(targetRole);
};

export const checkUserHasSomeRole = (props, targetRoles = []) => {
  if (!props?.auth || !props?.profile) return false;

  const { user_roles = [] } = props.profile.currentUser;
  if (user_roles.length === 0) return false;

  const userRoleTags = user_roles.map(({ role }) => role.tag);
  return targetRoles.some((role) => userRoleTags.includes(role));
};

export const checkUserHasAllRoles = (props, targetRoles = []) => {
  if (!props?.auth || !props?.profile) return false;

  const { user_roles = [] } = props.profile.currentUser;
  if (user_roles.length === 0) return false;

  const userRoleTags = user_roles.map(({ role }) => role.tag);
  return targetRoles.every((role) => userRoleTags.includes(role));
};
