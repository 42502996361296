import { combineReducers } from 'redux';
import projects from '../components/modules/projects/reducers';
import clients from '../components/modules/projects/reducers/clients';
import properties from '../components/modules/projects/reducers/properties';
import org_subscriptions from '../components/modules/projects/reducers/org_subscriptions';
import project_register from '../components/modules/projects/reducers/project_register';
import periods from '../components/modules/projects/reducers/periods';
import recon from '../components/modules/projects/reducers/recon';
import areas from '../components/modules/areas/reducers';
import stages from '../components/modules/projects/reducers/stages';
import document_groups from '../components/modules/projects/reducers/document_groups';
import rounds from '../components/modules/projects/reducers/rounds';
import sampling_plan from '../components/modules/projects/reducers/sampling_plan';
import download_reports from '../components/modules/projects/reducers/download_reports';
import sites from '../components/modules/sites/reducers';
import ajaxRequests from '../components/core/loader/reducers';
import auth from '../components/core/authentication/reducers';
import manage from '../components/manage/reducers';
import profile from '../components/manage/reducers/profile';
import realm from './realm';
import mapster from './mapster';
import activities from '../components/core/activity/reducers';
import lab from '../components/modules/lab/reducers';
import analytics from '../components/modules/analytics/reducers';
import crm from '../components/modules/projects/reducers/crm';
import office from '../components/modules/office/reducers';
import hashlinks from '../components/modules/portal/reducers';
import farmportrait_portal from '../components/modules/portal/reducers/farmportrait';
import pipelines from '../components/core/pipeline/reducers';
import enterprises from '../components/modules/portrait/reducers/enterprises';
import animal_classes from '../components/modules/portrait/reducers/animal_classes';
import attributes from '../components/modules/portrait/reducers/attributes';
import livestocks from '../components/modules/portrait/reducers/livestocks';
import overheads from '../components/modules/portrait/reducers/overheads';
import capital_expenditures from '../components/modules/portrait/reducers/capitalExpenditures';
import labours from '../components/modules/portrait/reducers/labours';
import liabilities from '../components/modules/portrait/reducers/liabilities';
import assets from '../components/modules/portrait/reducers/assets';
import direct_costs from '../components/modules/portrait/reducers/directCosts';
import property_other_incomes from '../components/modules/portrait/reducers/propertyOtherIncomes';
import other_business from '../components/modules/portrait/reducers/otherBusiness';
import off_farm_incomes from '../components/modules/portrait/reducers/offFarm';
import property_usages from '../components/modules/portrait/reducers/propertyUsages';
import property_rainfalls from '../components/modules/portrait/reducers/propertyRainfalls';
import wipstar from '../components/modules/wipstar/reducers';
import cropping from '../components/modules/portrait/reducers/cropping';
import wools from '../components/modules/portrait/reducers/wools';
import reports from '../components/modules/portrait/reducers/reports';
import report_preview from '../components/manage/reducers/report_preview';
import user_projects from '../components/modules/wipstar/reducers/user_projects';
import rosters from '../components/modules/wipstar/reducers/rosters';
import analysis_groups from '../components/manage/reducers/analysis_groups';
import importing from '../components/modules/portrait/reducers/importing';
import analysis_periods from '../components/manage/reducers/analysis_periods';
import admin from '../components/manage/reducers/admin';
import livestock_assumptions from '../components/manage/reducers/livestock_assumptions';

// Top level reducers
const appReducer = combineReducers({
  projects,
  clients,
  properties,
  org_subscriptions,
  project_register,
  periods,
  recon,
  areas,
  stages,
  document_groups,
  rounds,
  sampling_plan,
  sites,
  ajaxRequests,
  auth,
  manage,
  profile,
  realm,
  mapster,
  activities,
  lab,
  analytics,
  crm,
  office,
  hashlinks,
  farmportrait_portal,
  enterprises,
  pipelines,
  attributes,
  animal_classes,
  livestocks,
  overheads,
  capital_expenditures,
  labours,
  liabilities,
  assets,
  direct_costs,
  property_other_incomes,
  property_usages,
  property_rainfalls,
  wipstar,
  cropping,
  wools,
  other_business,
  off_farm_incomes,
  reports,
  report_preview,
  user_projects,
  rosters,
  download_reports,
  analysis_groups,
  importing,
  analysis_periods,
  admin,
  livestock_assumptions,
});

const rootReducer = (state, action) => {
  // Setting undefined state reinitializes each combined reducers default state.
  if (action.type === 'SET_LOGOUT_FULFILLED') return appReducer(undefined, action);

  return appReducer(state, action);
};

export default rootReducer;
