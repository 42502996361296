import moment from 'moment';
import { isEmpty, isNil } from 'lodash';
import { setFarmEyePreferences } from 'jsx/cookies';
import { defaults, allowed } from '../../../../lib/genericReducer';

const updatePreferenceCookie = (data, cookieKey) => {
  if (!isEmpty(data)) {
    // Filter out nil values (null/undefined)
    const preferences = Object.entries(data).map(([key, value]) => ({ key, value }));
    const filteredPreferences = preferences.filter(({ value }) => !isNil(value));

    if (!isEmpty(filteredPreferences)) setFarmEyePreferences(cookieKey, filteredPreferences);
  }
};

export default function reducer(
  state = {
    authorised: true,
    authResponseMessage: null,
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: 'business_config',
    businessConfigActiveTab: '1',
    enterprises: {},
    allocations: [],
    filteredAllocations: [],
    distributions: [],
    includeOrganisationPropertyOption: false,
    ranges: {
      min_date: null,
      max_date: null,
    },
    selectedRanges: {
      from_date: null,
      to_date: null,
    },
    selectedInterval: {
      interval: {},
      transaction_date: null,
    },
    local_timezone: moment().format('ZZ'),
    selectedProperties: [],
    distinctAssociatedDivisions: [],
  },
  action,
) {
  // Set expected reducer types
  const types = ['ENTERPRISE', 'BUSINESS_CONFIG', 'DISTINCT_ASSOCIATED_DIVISIONS'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_DISTINCT_ASSOCIATED_DIVISIONS_FULFILLED': {
      return {
        ...updatedState,
        distinctAssociatedDivisions: action.payload,
      };
    }
    case 'FETCH_ENTERPRISES_FULFILLED': {
      return {
        ...updatedState,
        enterprises: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_ALLOCATIONS_FULFILLED': {
      return {
        ...updatedState,
        allocations: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_RANGES_FULFILLED': {
      const ranges = action.payload;
      const { selectedRanges } = updatedState;
      if (!selectedRanges.from_date) selectedRanges.from_date = ranges.min_date;

      if (!selectedRanges.to_date) selectedRanges.to_date = ranges.max_date;

      return {
        ...updatedState,
        ranges: { ...updatedState.ranges, ...ranges },
        selectedRanges,
      };
    }
    case 'FETCH_ENTERPRISE_INTERVAL_RANGES_FULFILLED': {
      // default ranges from the /ranges API endpoint
      const selection = action.payload;
      const { selectedInterval } = updatedState;
      if (!selectedInterval.transaction_date)
        selectedInterval.transaction_date = selection.transaction_date;
      if (Object.keys(selectedInterval.interval).length === 0)
        selectedInterval.interval = selection.interval;

      return {
        ...updatedState,
        selectedInterval,
      };
    }
    case 'FETCH_ENTERPRISE_TRANSACTION_RANGES_FULFILLED': {
      return {
        ...updatedState,
        selectedRanges: { ...action.payload },
      };
    }
    case 'SET_ENTERPRISE_SELECTED_RANGES': {
      const values = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        values[key] = moment.utc(value).toISOString();
      });

      updatePreferenceCookie(values, 'ranges');

      return {
        ...updatedState,
        selectedRanges: {
          ...updatedState.selectedRanges,
          ...values,
        },
      };
    }
    case 'SET_ENTERPRISE_SELECTED_INTERVAL': {
      const transaction_date = moment.utc(action.payload.transaction_date).toISOString();
      const { interval } = action.payload;
      const { tag } = interval;

      const selectedInterval = { transaction_date, interval };

      let unitType;
      let from_date;
      switch (tag) {
        case 'all_time': {
          from_date = action.payload.from_date;
          break;
        }
        case 'year':
        case 'half_year': {
          from_date = moment(selectedInterval.transaction_date)
            .subtract(tag === 'year' ? 11 : 5, 'months')
            .startOf('month')
            .toISOString();

          break;
        }
        case 'month': {
          unitType = 'month';
          break;
        }
        case 'quarter': {
          unitType = 'quarter';
          break;
        }
        case 'day':
        default: {
          unitType = 'day';
        }
      }
      if (isNil(from_date))
        from_date = moment(selectedInterval.transaction_date).startOf(unitType).toISOString(true);

      // from the selectedInterval, calculate the selectedRanges
      const selectedRanges = { from_date, to_date: selectedInterval.transaction_date };

      updatePreferenceCookie(selectedRanges, 'ranges');
      updatePreferenceCookie(
        {
          interval_id: selectedInterval.interval.id,
          transaction_date: selectedInterval.transaction_date,
        },
        'interval',
      );

      return {
        ...updatedState,
        selectedInterval,
        selectedRanges,
      };
    }
    case 'SET_ENTERPRISES_ACTIVE_TAB': {
      return {
        ...updatedState,
        activeTab: action.payload,
      };
    }
    case 'SET_ENTERPRISE_SELECTED_PROPERTIES': {
      setFarmEyePreferences(null, [
        { key: 'selected_properties', value: JSON.stringify(action.payload) },
      ]);
      return {
        ...updatedState,
        selectedProperties: action.payload,
      };
    }
    case 'SET_ENTERPRISE_ORGANISATION_PROPERTY_SELECTED': {
      setFarmEyePreferences(null, [
        { key: 'include_organisation_property', value: JSON.stringify(action.payload) },
      ]);
      return {
        ...updatedState,
        includeOrganisationPropertyOption: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_DISTRIBUTIONS_FULFILLED': {
      return {
        ...updatedState,
        distributions: action.payload,
      };
    }
    case 'UNSET_ENTERPRISE_DISTRIBUTIONS': {
      return {
        ...updatedState,
        distributions: [],
      };
    }
    case 'SET_BUSINESS_CONFIG_ACTIVE_TAB': {
      return {
        ...updatedState,
        businessConfigActiveTab: action.payload,
      };
    }
    case 'SET_ENTERPRISE_ALLOCATION_FILTER': {
      return {
        ...updatedState,
        filteredAllocations: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
