import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { fetchAttributes } from '../actions/attributes';

import { controls as usageControls } from '../forms/propertyUsages';

import PropertyUsageDistributionsLsv from '../components/PropertyUsageDistributionsLsv';

import {
  fetchPropertyUsage,
  updatePropertyUsage,
  createPropertyUsage,
  removePropertyUsage,
} from '../actions/propertyUsages';
import { cloneDeep } from 'lodash';

class PropertyUsageModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(usageControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Property Usage',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.populateDefaultDistributions = this.populateDefaultDistributions.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(usageControls));
      controls.distributions.value = [];
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Property Usage',
      };

      this.props.dispatch(fetchAttributes({ type: 'property_usage_types' }));

      if (this.props.id) {
        const { id } = this.props;
        updatedState = { ...updatedState, id, isNew: false, title: 'Edit Property Usage' };

        this.props.dispatch(fetchPropertyUsage(id)).then((data) => {
          controls.distributions.value = data.distributions;
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  populateDefaultDistributions(existing_distributions) {
    const { property_usage_types } = this.props.attributes;
    const distributions = property_usage_types.map((item) => {
      let existing = null;
      if (existing_distributions) {
        existing = existing_distributions?.find(
          (distribution) => distribution.usage_type_id === item.id
        );
      }
      return {
        area_ha: existing?.area_ha ?? null,
        geom: existing?.geom ?? null,
        usage_type_id: item.id,
        usage_type: item,
      };
    });
    distributions.push({
      area_ha: null,
      usage_type: { name: 'Total Available' },
      usage_type_id: 'available_distribution',
      geom: null,
    });

    return distributions;
  }

  async onSave() {
    let { data } = this.state;
    const { controls, isNew } = this.state;
    const { property_usage_types } = this.props.attributes;
    const usage_type_ids = property_usage_types.map((usage_type) => usage_type.id);
    data = saveControls(controls, data);

    data.distributions = cloneDeep(controls.distributions.value);
    data.distributions.filter((distribution) =>
      usage_type_ids.includes(distribution.usage_type_id)
    );

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;

      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createPropertyUsage(data));
      } else {
        success = await this.props.dispatch(updatePropertyUsage(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_PROPERTY_USAGES_ATTRIBUTES' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing property usage permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removePropertyUsage(data.id));
      if (success) this.onClose(true);
    }
  }

  render() {
    const { controls, title, isNew } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.property_usages;
    const iconName = 'clipboard-list';

    controls.distributions.value = this.populateDefaultDistributions(controls.distributions.value);

    if (!controls.property_id?.value || controls.property_id?.value === null) {
      controls.property_id.value = this.props.property.id;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.open_date} />
            <FormInput handleChange={this.handleChange} control={controls.close_date} />
          </Form>
          {
            <PropertyUsageDistributionsLsv
              open_date={controls.open_date.value}
              close_date={controls.close_date.value}
              rows={controls.distributions.value}
            />
          }
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    attributes: store.attributes,
    enterprises: store.enterprises,
    property_usages: store.property_usages,
  };
};

export default connect(mapStoreToProps)(PropertyUsageModal);
