/* eslint-disable no-alert */ import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep } from 'lodash';
import {
  createAssumedAnimalClass,
  fetchAssumedAnimalClass,
  removeAssumedAnimalClass,
  updateAssumedAnimalClass,
} from 'jsx/components/manage/actions/livestock_assumptions';

import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

import {
  initControls,
  updateControls,
  saveControls,
  validateFormFieldControls,
  updateControlOptions,
} from 'jsx/components/core/form/lib/validateForm';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';

import { controls as controlsTemplate } from '../forms/livestock_assumptions';

class LivestockAssumptionsClassesModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      data: {},
      id: null,
      isNew: false,
      title: 'Animal Class',
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(controlsTemplate));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Benchmark Class',
      };

      // Populate the animal class types with the default division options.
      this.props.dispatch(
        fetchAttributes({ type: 'divisions', rules: { allowAnimalClasses: true } }),
      );

      if (this.props.id) {
        const { id } = this.props;
        updatedState = { ...updatedState, id, isNew: false, title: 'Edit Benchmark Class' };

        this.props.dispatch(fetchAssumedAnimalClass(id)).then((data) => {
          const updatedData = cloneDeep(data);
          updatedData.division_id = updatedData.type.parent_id;
          if (updatedData.division_id) this.loadAttributes(updatedData.division_id);
          this.setState({
            data: updatedData,
            controls: updateControls(controls, updatedData),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  onSave = async () => {
    let { data } = this.state;
    const { controls, isNew } = this.state;
    const { animal_class_types } = this.props.attributes;

    if (animal_class_types?.length === 0) controls.type_id.value = null;

    data = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createAssumedAnimalClass(data));
      } else {
        success = await this.props.dispatch(updateAssumedAnimalClass(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  };

  onClose = (refresh = false) => {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_ANIMAL_CLASS_TYPES_ATTRIBUTES' });
  };

  onRemove = async () => {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} class permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeAssumedAnimalClass(data.id));
      if (success) this.onClose(true);
    }
  };

  onChange = (event) => {
    const { controls } = this.state;
    const { value: old_division_id } = controls.division_id;
    this.handleChange(event);

    const { name, value } = event.target;

    switch (name) {
      case 'division_id':
        // Load another select;
        if (old_division_id !== value) {
          this.loadAttributes(value);
        }
        break;
      default:
        break;
    }
  };

  loadAttributes = (parent_id) => {
    if (parent_id === '' || parent_id === '-') {
      this.props.dispatch(fetchAttributes({ type: 'animal_class_types' }));
      return;
    }

    this.props.dispatch(fetchAttributes({ type: 'animal_class_types', parent_id }));
  };

  render() {
    let { controls } = this.state;
    const { title, isNew } = this.state;
    const { isOpen } = this.props;
    const { divisions, animal_class_types } = this.props.attributes;
    const { responseMessage } = this.props.livestock_assumptions;

    const iconName = 'paw';

    controls.type_id.disabled = animal_class_types.length === 0;
    const optionsCriteria = [
      { key: 'division_id', data: divisions },
      { key: 'type_id', data: animal_class_types },
    ];

    optionsCriteria.forEach(({ key, data }) => {
      controls = updateControlOptions(controls, key, data);
    });

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.onChange} control={controls.division_id} />
            <FormInput handleChange={this.onChange} control={controls.type_id} />
            <FormInputSwitch handleChange={this.handleChange} control={controls.use_average_values}
              />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, livestock_assumptions }) => ({
  attributes,
  livestock_assumptions,
});

export default connect(mapStoreToProps)(LivestockAssumptionsClassesModal);
