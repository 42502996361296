/* eslint-disable no-alert */
import { cloneDeep, isNil } from 'lodash';
import {
  createAnalysisGroupMember,
  removeAnalysisGroupMember,
} from 'jsx/components/manage/actions/analysis_group_members';
import FormBase from 'jsx/components/core/form/components/FormBase';
import { checkUserIsAdmin } from 'jsx/lib/userAccess';

import { Alert, Button, Col, Row } from 'reactstrap';
import ReactSelect from 'react-select';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { connect } from 'react-redux';

import { baseControls as memberControlsTemplate } from '../../../manage/forms/analysis_group_members';
import Mapster from '../../projects/containers/Mapster';

const lngLat = [150.7333, -23.1333];

class PropertyAnalysisGroups extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      mapSources: [],
      memberControls: cloneDeep(memberControlsTemplate),
      selectedAnalysisGroup: null,
    };
  }

  componentDidUpdate() {
    const { map } = this.state;

    // Force a map redraw
    if (map) map.resize();
  }

  onAnalysisGroupChange = (selectedAnalysisGroup) => {
    this.setState({ selectedAnalysisGroup });
  };

  onAddMemberToAnalysisGroup = async () => {
    const { selectedAnalysisGroup } = this.state;
    const updateBody = {
      analysis_group_id: selectedAnalysisGroup.value,
      property_id: this.props.propertyId,
    };
    const success = await this.props.dispatch(createAnalysisGroupMember(updateBody));
    if (success) this.props.onRefresh();
  };

  onAnalysisGroupMemberRemove = async (id) => {
    const { members } = this.props.analysis_groups;
    const { group, property } = members.rows.find(({ id: memberId }) => memberId === id);
    const confirmed = window.confirm(
      `Removing ${property.name} from ${group.name} permanently. Continue?`,
    );
    if (!confirmed) return;

    const success = await this.props.dispatch(removeAnalysisGroupMember(id));
    if (success) this.props.onRefresh();
  };

  onAlertDismiss = () =>
    this.props.dispatch({ type: 'SET_ANALYSIS_GROUP_MEMBER_RESPONSE_MESSAGE', payload: null });

  render() {
    const { groups, members, responseMessage } = this.props.analysis_groups;
    const { memberControls, selectedAnalysisGroup, mapSources } = this.state;
    const analysisGroupOptions = groups.rows.map(({ id: groupId, name }) => ({
      value: groupId,
      label: name,
    }));
    const isAdmin = checkUserIsAdmin(this.props);

    return (
      <>
        <Alert
          isOpen={!isNil(responseMessage)}
          color="danger"
          className="py-4 text-center"
          toggle={this.onAlertDismiss}
        >
          <strong className="align-middle">{responseMessage}</strong>
        </Alert>
        <Row className="h-100">
          <Col xs={5}>
            <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
              <h5>Analysis Groups</h5>
              {isAdmin && (
                <div className="d-flex justify-content-end">
                  <ReactSelect
                    options={analysisGroupOptions}
                    placeholder="Select Analysis Group"
                    onChange={this.onAnalysisGroupChange}
                    value={selectedAnalysisGroup}
                    isClearable
                  />
                  <Button
                    className="ml-2"
                    size="sm"
                    color="success"
                    onClick={this.onAddMemberToAnalysisGroup}
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
            <GenericLsv
              actions={
                isAdmin
                  ? [
                      {
                        func: (memberId) => this.onAnalysisGroupMemberRemove(memberId),
                        iconName: 'trash',
                        classes: 'text-danger mr-1',
                      },
                    ]
                  : []
              }
              controls={memberControls}
              emptyCaption="No Member associations found"
              iconName="people-group"
              rows={members.rows}
            />
          </Col>
          <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
            <Mapster
              expandMap={false}
              lngLatCenter={lngLat}
              mapSources={mapSources}
              onMapLoad={this.onMapLoad}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ analysis_groups, auth, profile }) => ({
  analysis_groups,
  auth,
  profile,
});

export default connect(mapStoreToProps)(PropertyAnalysisGroups);
