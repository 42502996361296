import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { GenericModal } from '../../../core/form/components/GenericModal';
import {
  initControls,
  updateControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';

class LivestockAdjustmentModal extends GenericModal {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isOpeningAdjustment: false,
      setModal: this.props.setModal,
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { modalTitle } = this.props;
      const controls = initControls(cloneDeep(this.props.controls));

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: `New ${modalTitle}`,
      };

      if (this.props.data?.id) {
        const { data } = this.props;
        const isOpeningAdjustment = data?.adjustment_type?.tag === 'opening_quantity';

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id: data.id,
          isNew: false,
          isOpeningAdjustment,
          title: `Edit ${modalTitle}`,
        };
      }

      this.setState(updatedState);
    }
  }

  getEnterpriseProperties = (enterprise_id) => {
    if (!enterprise_id) return [];

    // Only display properties that have been previously allocated to the enterprise
    const { enterprises } = this.props.enterprises;
    const enterprise = enterprises?.rows.find(({ id }) => id === enterprise_id);
    if (!enterprise || enterprises.allocations?.length === 0) return [];

    return enterprise.allocations.map(({ open_date, property }) => ({ ...property, open_date }));
  };

  getOpeningDate = (property_id, enterprise_properties = []) => {
    const { selectedRanges } = this.props.enterprises;
    const defaultOpeningDate = this.formatOpeningTransactionDateString(selectedRanges.from_date);

    const selectedProperty = enterprise_properties.find(({ id }) => id === property_id);

    return selectedProperty
      ? this.formatOpeningTransactionDateString(selectedProperty.open_date)
      : defaultOpeningDate;
  };

  formatOpeningTransactionDateString = (date) => moment(date).toISOString();

  onEnterpriseChange = (enterprise_id) => {
    const { controls, isOpeningAdjustment } = this.state;
    const updatedControls = cloneDeep(controls);
    updatedControls.enterprise_id.value = enterprise_id;

    if (!isOpeningAdjustment) {
      this.setState({ controls: updatedControls });
      return;
    }

    const enterpriseProperties = this.getEnterpriseProperties(enterprise_id);
    if (enterpriseProperties.length === 0) return;

    // Update opening date to the first property of the selected enterprise
    const [{ open_date }] = enterpriseProperties;
    const openingDate = this.formatOpeningTransactionDateString(open_date);

    updatedControls.transaction_date.value = openingDate;

    this.setState({ controls: updatedControls });
  };

  onPropertyChange = (property_id) => {
    const { controls, isOpeningAdjustment } = this.state;
    const updatedControls = cloneDeep(controls);
    updatedControls.property_id.value = property_id;

    if (!isOpeningAdjustment) {
      this.setState({ controls: updatedControls });
      return;
    }

    const enterpriseProperties = this.getEnterpriseProperties(controls.enterprise_id.value);
    if (enterpriseProperties.length === 0) return;

    const openingDate = this.getOpeningDate(property_id, enterpriseProperties);

    updatedControls.transaction_date.value = openingDate;
    this.setState({ controls: updatedControls });
  };

  onChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'property_id': {
        this.onPropertyChange(value);
        break;
      }
      case 'enterprise_id': {
        this.onEnterpriseChange(value);
        break;
      }
      default: {
        const updatedControls = cloneDeep(this.state.controls);
        updatedControls[name].value = value;
        this.setState({ controls: updatedControls });
        break;
      }
    }
  };

  render() {
    let { controls } = this.state;
    const { title, isNew, description } = this.state;
    const { iconName, isOpen, responseMessage } = this.props;

    controls = this.setOptions(controls);

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    if (enterprise_id) {
      const filteredProperties = this.getEnterpriseProperties(enterprise_id);
      controls = updateControlOptions(controls, 'property_id', filteredProperties);
      controls.property_id.showInEditor = filteredProperties.length > 1;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>{!isEmpty(controls) ? this.renderInputs(controls, this.onChange) : null}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ enterprises }) => ({
  enterprises,
});

export default connect(mapStoreToProps)(LivestockAdjustmentModal);
