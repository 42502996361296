import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  code_search: {
    ...controlDefaults,
    name: 'code_search',
    type: 'search',
    caption: 'Code Search',
    placeholder: 'Type your cost code to start searching',
    description:
      'Minimum 3 characters to search. You can search by Job/Phase Code, Job/Phase Name, Project Name or Job No',
    options: [],
    validationRules: {
      isRequired: true,
      minLength: 3,
    },
  },
  costcode_id: {
    ...controlDefaults,
    name: 'costcode_id',
    type: 'hidden',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  phasecode_id: {
    ...controlDefaults,
    name: 'phasecode_id',
    type: 'hidden',
    validationRules: {
      isRequired: true,
    },
  },
  from_date: {
    ...controlDefaults,
    placeholder: 'From',
    name: 'from_date',
    type: 'date',
    caption: 'Date',
    validationRules: {
      isRequired: true,
    },
    warning: false,
  },
  to_date: {
    ...controlDefaults,
    placeholder: 'To',
    name: 'to_date',
    type: 'datetime-local',
    caption: 'To Date/Time',
  },
  from_time: {
    ...controlDefaults,
    placeholder: 'From Time',
    name: 'from_time',
    type: 'text',
    caption: 'From',
    defaultValue: '',
    validationRules: {},
  },
  to_time: {
    ...controlDefaults,
    placeholder: 'To Time',
    name: 'to_time',
    type: 'text',
    caption: 'To',
    defaultValue: '',
    validationRules: {},
  },
  break_hours: {
    ...controlDefaults,
    name: 'break_hours',
    type: 'number',
    caption: 'Break (Decimal Hrs)',
    defaultValue: 0,
    validationRules: {},
  },
  jira_key: {
    ...controlDefaults,
    name: 'jira_key',
    type: 'text',
    caption: 'Jira Key',
    validationRules: {},
  },
  comments: {
    ...controlDefaults,
    placeholder: 'Comments',
    name: 'comments',
    type: 'textarea',
    caption: 'Work Description',
    persist: false,
    validationRules: {
      isRequired: true,
    },
  },
};
