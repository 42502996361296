export const BenchmarkDataRow = ({
  categoryName = '',
  metricName = '',
  colour = '',
  results = [],
}) => (
  <tr>
    <td className="font-weight-bold">{categoryName}</td>
    <td className={`text-${colour}`}>{metricName}</td>
    {results.map((result, index) => (
      <td key={index} className={`text-right text-${colour}`}>
        {result}
      </td>
    ))}
  </tr>
);
