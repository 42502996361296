/* eslint-disable arrow-body-style */
import { Container, Col, Label, Row, Table, UncontrolledPopover, PopoverBody } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Select from 'react-select';
import { Spacer } from 'jsx/components/core/layout/Spacer';
import { BenchmarkDataRow } from '../components/benchmarking/BenchmarkDataRow';
import { BenchmarkSection } from '../components/benchmarking/BenchmarkSection';

/**
 * Context for future development.
 *
 * We will be fetching by sections: E.g. Profitability, People, Productivity, etc.
 * All division/enterprise type building will be done on the API (Productivity). SPA will just be populating sections and rows.
 */

const addHeight = (text) => (
  <div className="d-flex justify-content-center">
    <span className="mb-1">{text}</span>
  </div>
);

const getPopover = () => {
  return (
    <UncontrolledPopover placement="right" target="ClientValuesInfo" trigger="hover">
      <PopoverBody>
        <p className="p-0 m-0">Based on the values entered in the stockflows and cropflows.</p>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export const Benchmarks = () => {
  return (
    <Container fluid>
      <Spacer />
      <Row>
        <Col xs="9" />
        <Col xs="3">
          <Label className="font-weight-bold ml-1 mb-1">Benchmark Group</Label>
          <Select
            options={[
              { label: 'RCS Main', value: '1' },
              { label: 'Test', value: '2' },
            ]}
            placeholder="Select Benchmark Group"
            defaultValue={{ label: 'RCS Main', value: '1' }}
          />
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Table hover bordered responsive>
          <thead className="text-center border-top">
            <tr className="Benchmarks Last Calculated: 06/03/2024">
              <th colSpan="2" className="border-bottom-0" style={{ fontSize: '14px' }}>
                Benchmarks Last Calculated: 06/03/2024
              </th>
              <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                Your Result
              </th>
              <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                Group Results
              </th>
            </tr>
            <tr className="border-top">
              <th></th>
              <th>{addHeight('Metric')}</th>
              <th className="bg-yellow-20">
                Client Values
                <Icon
                  id="ClientValuesInfo"
                  as="button"
                  name="info-circle"
                  title="Client Values Information"
                  className="ml-1 p-0"
                />
                {getPopover()}
              </th>
              <th className="bg-pink-20">{addHeight('Benchmark Values')}</th>
              <th className="bg-pink-20">{addHeight('3 Year Average')}</th>
              <th className="bg-blue-20">{addHeight('Top 20%')}</th>
              <th className="bg-blue-20">{addHeight('Group Average')}</th>
              <th className="bg-blue-20">{addHeight('Benchmark')}</th>
            </tr>
          </thead>
          <tbody>
            <BenchmarkSection name="Profitability" colour="green-20" />
            <BenchmarkDataRow
              categoryName="Economic Profit Measures"
              metricName="ROA"
              results={['', '0.9%', '-1.5%', '7.5%', '2.3%', '5.9%']}
            />
            <BenchmarkDataRow
              colour="pink"
              metricName="Change in Net Worth"
              results={['', '$327,010', '$0', '$0', '$0', '$0']}
            />
            <BenchmarkDataRow
              colour="red"
              metricName="Land ROA"
              results={['', '17.1%', '8.6%', '17.8%', '16.1%', '12.0%']}
            />
            <BenchmarkDataRow
              colour="red"
              metricName="Production ROA"
              results={['', '-9.7%', '-27.8%', '19.3%', '-5.1%', '13.0%']}
            />
            <BenchmarkDataRow
              metricName="EBIT"
              results={['', '29.60 $/ha', '-43.50 $/ha', '30.90 $/ha', '30.40 $/ha', '32.30 $/ha']}
            />
            <BenchmarkDataRow
              categoryName="Three Secrets of Profit"
              metricName="Asset Turnover Ratio"
              results={['', '7.2%', '5.8%', '13.0%', '7.9%', '12.0%']}
            />
            <BenchmarkDataRow
              metricName="Gross Margin Ratio"
              results={['', '22.0%', '17.0%', '68.0%', '59.0%', '64.0%']}
            />
            <BenchmarkDataRow
              metricName="Overhead Ratio"
              results={['', '53.0%', '80.0%', '26.0%', '57.0%', '29.0%']}
            />
            <BenchmarkDataRow
              categoryName="Finance Ratio"
              metricName="Finance Ratio"
              results={['', '', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Closing Ag Equity Ratio"
              results={['', '90%', '90%', '80%', '84%', '76%']}
            />
            <BenchmarkDataRow
              categoryName="Net Cash Flows"
              metricName="EBIT per Hectare"
              results={['', '', '', '', '', '']}
            />
            <BenchmarkDataRow
              colour="pink"
              metricName="Cash Business Profit"
              results={['', '', '', '', '', '']}
            />
            <BenchmarkDataRow
              colour="pink"
              metricName="Economic Business Profit"
              results={['', '', '', '', '', '']}
            />
            <BenchmarkDataRow metricName="Expense Ratio" results={['', '', '', '', '', '']} />
            <BenchmarkSection name="People" colour="blue-20" />
            <BenchmarkDataRow
              metricName="Total Labour Units (Full Time Equivalent)"
              results={['', '3.65 FTE', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Gross Product per FTE"
              results={['', '$ 54,420 FTE', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Animal Units Managed per FTE"
              results={['', '1,663 AU/FTE', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Property Development Labour (CAPEX)"
              results={['', '4.0 weeks', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Training days per FTE"
              results={['', '4.20 days', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Holidays per FTE"
              results={['', '8.30 days', '', '', '', '']}
            />
            <BenchmarkDataRow
              colour="red"
              metricName="Farm Area per FTE"
              results={['', '973 ha/FTE', '', '', '', '']}
            />
            <BenchmarkSection
              name="Productivity
                (based on enterprise 'type')"
              colour="red-20"
            />
            <BenchmarkDataRow
              categoryName="Cattle - Meat"
              metricName="Meat Produced"
              results={['', 'kg/ha', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Meat Produced / 100mm"
              results={['', 'kg/ha/100mm', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Cost of Production"
              results={['', '$/kg', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Average Meat Sale Price"
              results={['', '$/kg', '', '', '', '']}
            />
            <BenchmarkDataRow
              colour="red"
              metricName="Meat Costs"
              results={['', '$/LSU', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Meat Gross Margin"
              results={['', '$/ha', '', '', '', '']}
            />
            <BenchmarkDataRow categoryName="Cattle - Feedlot" results={['', '', '', '', '', '']} />
            <BenchmarkDataRow categoryName="Sheep - Meat" results={['', '', '', '', '', '']} />
            <BenchmarkDataRow categoryName="Sheep - Wool" results={['', '', '', '', '', '']} />
            <BenchmarkDataRow
              categoryName="Crop - Crop Type"
              metricName="Yield"
              results={['', 'kg/ha', '', '', '', '']}
            />
            <BenchmarkDataRow
              metricName="Costs (ex Finance)"
              results={['', '$/kg', '', '', '', '']}
            />
            <BenchmarkDataRow metricName="Price Received" results={['', '$/kg', '', '', '', '']} />
            <BenchmarkDataRow metricName="Gross Margin" results={['', '$/ha', '', '', '', '']} />
            <BenchmarkSection name="Property" colour="yellow-20" />
            <BenchmarkDataRow categoryName="SDH/100mm" results={['', '', '', '', '', '']} />
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};
