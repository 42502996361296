import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/propertyUsages';
import Mapster from '../../projects/containers/Mapster';
import PropertyUsageModal from '../containers/PropertyUsageModal';

import { fetchPropertyUsages } from '../actions/propertyUsages';
import GenericLsv from '../../../core/form/components/GenericLsv';
import { cloneDeep } from 'lodash';

class PropertyUsages extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      data: {},
      controls: cloneDeep(controls),
      mapSources: [],
      map: null,
    };

    this.setModal = this.setModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.addDistributionControls = this.addDistributionControls.bind(this);
  }

  componentDidUpdate() {
    let { map } = this.state;
    // Force a map redraw
    if (map) map.resize();
  }

  goBack() {
    this.props.history.goBack();
  }

  onRefresh() {
    this.props.dispatch(fetchPropertyUsages({ property_id: this.props.property.id }));
  }

  async setModal(isModalOpen, id = null) {
    let newState = {
      id,
      isModalOpen,
    };

    this.setState(newState);
  }

  addDistributionControls(controls, rows) {
    const { distribution_default } = controls;

    // Check rows exist
    if (rows.rows?.length > 0) {
      let keys = {};
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row.distributions?.length > 0) {
          // Iterate through each distribution
          row.distributions.forEach((distribution, idx) => {
            // Add distribution control
            const { tag, name } = distribution.usage_type;
            const key = `${name.toString().toLowerCase()}_distribution`;
            const fieldName = `distributions.${(idx++).toString()}.area_ha`;
            keys[tag] = fieldName;

            controls[key] = {
              ...distribution_default,
              caption: `${name} (ha)`,
              fieldName,
              formattingRules: {
                includeCommas: true,
                includeDecimals: true,
              },
              name: `${key}`,
              showInListview: true,
            };
          });
        }

        // Add calculated available area
        controls.available_distribution = {
          ...distribution_default,
          caption: 'Total Available (ha)',
          name: 'available_distribution',
          formula: `##${keys.property_owned}## + ##${keys.property_leased}## - ##${keys.property_leasing}## - ##${keys.property_unavailable}##`,
          formattingRules: {
            includeCommas: true,
            includeDecimals: true,
          },
          type: 'number',
          showInListview: true,
        };
      });
    }
    return controls;
  }

  render() {
    const { isModalOpen, id } = this.state;
    const { controls, mapSources } = this.state;
    const { property_usages } = this.props.property_usages;

    let lngLat = [150.7333, -23.1333];
    let iconName = 'draw-polygon';
    let emptyCaption = 'No usage distributions found';

    return (
      <Row className="h-100">
        <Col sm={7} className="m-0 p-2">
          <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
            <h5>Usage Distribution History</h5>
            <Button
              onClick={() => {
                this.setModal(true);
              }}
              size="sm"
              color="success"
            >
              Add Usage Distribution
            </Button>
          </div>
          <PropertyUsageModal
            onRefresh={this.onRefresh}
            setModal={this.setModal}
            id={id}
            isOpen={isModalOpen}
            isNew={id === null}
            property={this.props.property}
          />
          <GenericLsv
            controls={this.addDistributionControls(controls, property_usages)}
            iconName={iconName}
            emptyCaption={emptyCaption}
            onClick={(id) => {
              this.setModal(true, id);
            }}
            rows={property_usages}
          />
        </Col>
        <Col sm={5} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            handleSourceVisibility={() => {}}
            expandMap={false}
            lngLatCenter={lngLat}
            toggleMap={() => {}}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage,
    portal: store.farmportrait_portal,
    property_usages: store.property_usages,
  };
};

export default connect(mapStoreToProps)(PropertyUsages);
