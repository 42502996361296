import React from 'react';
import { Button } from 'reactstrap';
import Invitation from '../../../../manage/containers/Invitation';

const ClientToolbar = (props) => {
  const { setModal, isOpen, orgId, isChildAppInvite, showSendButton } = props;

  return (
    <div className="m-1 d-flex justify-content-end">
      <Invitation
        setModal={setModal}
        isOpen={isOpen}
        orgId={orgId}
        isChildAppInvite={isChildAppInvite}
        showSendButton={showSendButton}
      />
      <Button onClick={() => setModal(true)} size="sm" color="success">
        Invite
      </Button>
    </div>
  );
};

export default ClientToolbar;
