import React, { useCallback, useEffect, useReducer } from 'react';

import { Button, Alert, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { fetchOrgSubscriptions, updateOrgSubscriptions } from '../../actions/org_subscriptions';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';
import SubscriptionRow from './SubscriptionRow';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';

const reducer = (state, { type, record }) => ({ ...state, [type]: record });
const Subscriptions = ({ orgId }) => {
  const [records, setNewRecord] = useReducer(reducer, {});
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAttributes({ type: 'subscriptions' }));
  }, [dispatch]);
  useEffect(() => {
    if (orgId) {
      dispatch(fetchOrgSubscriptions(orgId));
    }
  }, [orgId, dispatch]);
  const onSave = () => {
    const recordsToSave = Object.values(records).filter((record) => !isNil(record));
    dispatch(updateOrgSubscriptions(orgId, recordsToSave));
  };

  const recordUpdated = useCallback((type, record) => {
    setNewRecord({ type, record });
  }, []);

  return (
    <>
      <Alert color="primary">
        Subscriptions currently enabled for this client. It is possible to choose a date that the
        subscription expires on.
      </Alert>
      <Table>
        <thead>
          <tr>
            <th className="w-200px">Subscription</th>
            <th className="w-100px">Enabled</th>
            <th className="w-100px">Expires</th>
            <th className="w-200px">On</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <SubscriptionRow
            type="Member Portal"
            caption="Member Portal"
            orgId={orgId}
            recordUpdated={recordUpdated}
          />
          <SubscriptionRow
            type="Analytics"
            caption="Analytics"
            orgId={orgId}
            recordUpdated={recordUpdated}
          />
        </tbody>
      </Table>
      <div className="d-flex justify-content-center m-2">
        <Button className="mr-2" size="sm" color="success" onClick={onSave}>
          Update Subscriptions
        </Button>
        <Button className="mr-2" size="sm" color="light" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default Subscriptions;
