import React from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import { accessToken } from '../../../../constants/map.js';
import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/property.js';
import { HalfCircleSpinner } from 'react-epic-spinners';

import { Row, Col, Button } from 'reactstrap';

import { saveControls } from '../../../core/form/lib/validateForm';

import {
  fetchLibrarySources,
  fetchWMSObject,
  convertGeometry,
  createProperty,
} from '../../projects/actions/properties';

import { createPropertyLot, removePropertyLot } from '../../projects/actions/property_lots';

import {
  loadSource,
  buildGetFeatureInfoParams,
  splitUrl,
  setSelectedLots,
  getSelectedLots,
  formatSelectedLot,
  toggleSelectedLot,
} from '../../projects/lib/propertySearchUtils';

import {
  zoomToBounds,
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  getCollectionBoundary,
} from '../../projects/lib/mapster.js';

import { fetchPropertyLots } from '../../projects/actions/property_lots';

mapboxgl.accessToken = accessToken;

class PropertyLots extends FormBase {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.geocoderRef = React.createRef();

    this.state = {
      lng: 150.7333,
      lat: -23.1333,
      zoom: 8,
      map: null,
      geocoder: null,
      draw: null,
      selected: [],
      controls: controls,
      data: {},
      property_address: null,
      property_name: null,
      propertySource: 'property_selection',
      searchCenter: [],
      mapStylePath: 'mapbox://styles/mapbox',
      mapStyles: [
        { id: 'outdoors-v11', name: 'Outdoors' },
        { id: 'satellite-v9', name: 'Satellite' },
        { id: 'dark-v10', name: 'Dark' },
        { id: 'light-v10', name: 'Light' },
      ],
      currentStyle: 'satellite-v9',
      currentSource: null,
      isModalOpen: false,
      hashlink: null,
      searchCompleted: false,
      mapDataLoading: true,
      property_id: null,
    };

    this.loadDefaultSource = this.loadDefaultSource.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.loadProperties = this.loadProperties.bind(this);
    this.loadPropertySource = this.loadPropertySource.bind(this);
    this.getTotalHa = this.getTotalHa.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.loadProjectProperty = this.loadProjectProperty.bind(this);
  }

  async componentDidMount() {
    let { mapStylePath, currentStyle, lat, lng, zoom, controls } = this.state;

    // Load Map
    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: `${mapStylePath}/${currentStyle}`,
      center: [lng, lat],
      zoom: zoom,
    });

    // Set Navigation
    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
    });
    map.addControl(nav);

    // Set Geocoder
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    // geocoder.onAdd(map)
    this.geocoderRef.current.appendChild(geocoder.onAdd(map));

    // Geocode onResult
    geocoder.on('result', async (e) => {
      if (
        e.result.center &&
        JSON.stringify(e.result.center) !== JSON.stringify(this.state.searchCenter)
      ) {
        controls.address.value = e.result.place_name;
        this.setState(
          {
            searchCenter: e.result.center,
          },
          () => {
            // Set address marker
            new mapboxgl.Marker().setLngLat(e.result.center).addTo(map);
          }
        );
      }
    });

    const selected = getSelectedLots();
    this.setState({ map, geocoder, selected });

    // Map onLoad
    map.on('load', async () => {
      await this.loadDefaultSource(map);
      await this.loadPropertySource();
      this.loadProperties();

      this.setState({ mapDataLoading: false });

      if (this.props.onMapLoad) {
        this.props.onMapLoad(map);
      }
    });

    // Map Click
    map.on('click', async (e) => this.selectFeature(e));
  }

  async loadProjectProperty(property_id) {
    let { selected } = this.state;

    selected = [];
    if (property_id) {
      // Fetch lots from project property
      const results = await this.props.dispatch(fetchPropertyLots(property_id));
      results.map((row) => {
        const property = { properties: { lotplan: row.lotplan, ha: row.ha } };
        selected.push({
          ...property,
          geometry: row.geom,
        });
        return false;
      });

      setSelectedLots(selected);
      // this.setState({selected});
    }
  }

  async loadPropertySource() {
    const { map, propertySource } = this.state;

    // // Remove property source if already set
    if (map.getSource(propertySource)) {
      map.removeSource(propertySource);
    }

    // Load Blank Property Source
    map.addSource(propertySource, {
      type: 'geojson',
      data: { type: 'Feature' },
    });

    // Add lot polygons
    const boundary_layer = getDefaultLayer(propertySource, 'MultiPolygon');
    map.addLayer(boundary_layer);

    const label_layer = getDefaultLayer(propertySource, 'PolyLabel');
    map.addLayer(label_layer);
  }

  async loadProperties(zoomExtent = false) {
    const { selected, opacity, propertySource, map } = this.state;

    if (!map) return;

    map.getSource(propertySource).setData(buildFeatureCollection());
    if (selected.length > 0) {
      const featureCollection = buildFeatureCollection();

      selected.forEach((property) => {
        let colour = 'yellow';
        let outline = '#000000';
        let fillOpacity = opacity / 100;

        const feature = buildFeature(property.geometry, {
          id: property.id,
          lotplan: property.properties.lotplan,
          ha: property.ha,
          geom: property.geometry,
          colour: colour,
          outline: outline,
          fillOpacity: fillOpacity,
        });

        featureCollection.features.push(feature);
      });

      // Set properties
      map.getSource(propertySource).setData(featureCollection);

      // Optionally zoom to extent of radius
      if (zoomExtent) {
        zoomToBounds(map, getCollectionBoundary(featureCollection));
      }
    }
  }

  async loadDefaultSource(map) {
    const librarySources = await this.props.dispatch(fetchLibrarySources());

    const source = librarySources.find(
      (librarySource) => librarySource.layer === 'carbonlink:australia_dcdb_lotplan'
    );
    source.styles = ['boundaries-only'];

    loadSource(map, source);

    this.setState({ currentSource: source });
  }

  setSelected(property) {
    let { selected } = this.state;

    const property_id = this.props.property.id;

    // Immediately update lot selection in db
    if (selected.find((s) => s.properties.lotplan === property.properties.lotplan)) {
      this.props.dispatch(removePropertyLot(property_id, property.properties.lotplan));
    } else {
      this.props.dispatch(
        createPropertyLot({
          property_id,
          lotplan: property.properties.lotplan,
          geom: property.geometry,
          ha: property.properties.ha,
        })
      );
    }

    // Format and toggle selected lots
    property = formatSelectedLot(property);
    selected = toggleSelectedLot(property);

    // Set state
    this.setState({ selected });
    this.loadProperties();
  }

  async clearSelected() {
    // Clear Selected
    await this.setState({
      selected: setSelectedLots(null),
    });
    this.loadProperties();
  }

  async selectFeature(event) {
    const {
      currentSource,
      map,
      // hashlink
    } = this.state;

    // axios.defaults.headers.common['hash-key'] = hashlink.hash_key;

    let mapSource = map.getSource(currentSource.id);
    if (!mapSource) return false;

    let urlSource = splitUrl(mapSource.tiles[0]);
    let url = currentSource.url;
    let params = {
      ...urlSource.params,
      layers: currentSource.layer,
    };

    // Get Feature
    let featureInfoParams = buildGetFeatureInfoParams(params, event.lngLat);
    this.props.dispatch(fetchWMSObject(url, {}, featureInfoParams)).then((response) => {
      let clickedFeature = response.features[0];
      if (clickedFeature && featureInfoParams.crs !== `EPSG:4326` && clickedFeature.geometry) {
        const epsg = featureInfoParams.crs.split(':')[1];
        this.props
          .dispatch(convertGeometry({ geom: clickedFeature.geometry, epsg }))
          .then((converted) => {
            clickedFeature.geometry = converted.geom;
            clickedFeature.properties = {
              ...clickedFeature.properties,
              ha: converted.ha,
            };
            this.setSelected(clickedFeature);
          });
      } else {
        window.alert(
          'Unable to select geometry. Contact your administrator. There may not be cadastre information for this area.'
        );
      }
    });
  }

  getTotalHa() {
    let ha = 0;
    this.state.selected.map((property) => {
      ha = ha + parseFloat(property.properties?.ha);
      return true;
    });
    return ha.toFixed(2);
  }

  async onSave() {
    let { data, controls } = this.state;

    const { project } = this.props.portal;

    data = saveControls(controls, data);
    data.idx = data.name.substring(0, 4).toUpperCase();
    data.project_id = project.id;

    await this.props.dispatch(createProperty(data));

    // const success = await this.props.dispatch(updateProponentProperty(hashlink.id, data));
    // if (success) {
    //   searchCompleted = true;
    //   this.clearSelected();
    //   this.setState({isModalOpen: false, searchCompleted});
    // }
  }

  onCancel() {
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  render() {
    const { selected, searchCompleted, mapDataLoading } = this.state;

    const responseMessage = null;
    const title = 'Build your property';

    const ha = this.getTotalHa();
    const totalHa = `${selected.length} Lots/${ha} ha`;

    return (
      <div className="m-0 p-0 h-100">
        {!responseMessage && !searchCompleted && (
          <Row className="m-0 p-0 h-100">
            <Col className="listview">
              <div className="text-center">
                <h5 className="text-corporate mt-2">{title}</h5>
                <div className="text-whitee p-2">
                  This space enables you to identify the lots making up your property boundaries by
                  just clicking on lots or areas on the map. Start with typing in your address.
                </div>
              </div>

              <div className="mt-3">
                <div className="ml-4 text-corporate">Search Property Address:</div>
                <div className="mt-1 ml-3 mr-3 mt-0" ref={this.geocoderRef}></div>
              </div>

              <div className="border-top m-1 mt-3 p-2 border-corporate">
                All lots are saved automatically on selection
              </div>
              <div className="d-flex justify-content-center m-2 p-2">
                <Button
                  disabled={selected.length === 0}
                  size="sm"
                  color="danger"
                  className="ml-2"
                  onClick={() => this.clearSelected()}
                >
                  Clear Lots
                </Button>
                <Button size="sm" color="link" className="ml-2" onClick={() => {}}>
                  Cancel
                </Button>
              </div>

              <h3 className="text-center bg-corporate text-white p-2 mt-3">{totalHa}</h3>
            </Col>
            <Col style={{ position: 'relative' }}>
              <div ref={this.mapRef} className="mapster" />

              {mapDataLoading && (
                <div
                  style={{ position: 'absolute', right: 60, top: 10 }}
                  className="d-flex justify-content-end pt-3"
                >
                  <HalfCircleSpinner size={20} color="#ffffff" />
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    mapster: store.mapster,
    profile: store.profile,
    portal: store.farmportrait_portal,
  };
};

export default connect(mapStoreToProps)(PropertyLots);
